<template>
  <office-layout>
    <template v-slot:breadcrumb>
      <a-breadcrumb>
        <a-breadcrumb-item>
          <HomeOutlined />
          <router-link to="/">
            <span>Home</span>
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <span>Redeem List</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </template>
    <div>
      <a-col :md="{ span: 24 }" :xs="{ span: 24 }">
        <a-table
          :columns="columns"
          :row-key="(record) => record.id"
          :data-source="redeems"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >
          <template
            #filterDropdown="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
          >
            <div style="padding: 8px">
              <a-input
                ref="searchInput"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                <template #icon><SearchOutlined /></template>
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px"
                @click="handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
          </template>
          <template #filterIcon="filtered">
            <search-outlined
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
          </template>
          <template #firstname="{ record }">
            {{ record.name }}
          </template>
          <template #checkdate="{ record }">
            {{ moment(record.bookingCheckIn).format("YYYY-MM-DD") }} to
            {{ moment(record.bookingCheckOut).format("YYYY-MM-DD") }}
            <br />
            <br />
            Rooms: {{ record.rooms }}<br />
            Nights: {{ record.nights }}<br />
            Room nights: {{ record.roomNights }}
          </template>
          <template #total="{ record }">
            {{ record.total }}
          </template>
          <template #status="{ record }">
            {{ record.status }}
          </template>
          <template #documents="{ record }">
            <p>Arrival Port: {{ record.arrivalPort }}</p>
            <a-button
              v-if="record.bookingConfirmation"
              type="link"
              @click="
                openDocument(
                  record.bookingConfirmation.bucketName,
                  record.bookingConfirmation.fileName
                )
              "
              primary
            >
              Booking Confirmation
            </a-button>
            <a-button v-else type="link">
              <div class="uploadImgLabel">Booking Confirmation</div>
            </a-button>
            <br />
            <a-button
              v-if="record.bordingPass"
              type="link"
              @click="
                openDocument(
                  record.bordingPass.bucketName,
                  record.bordingPass.fileName
                )
              "
              primary
            >
              Boarding Pass or Air Ticket
            </a-button>
            <a-button v-else type="link">
              <div class="uploadImgLabel">Boarding Pass or Air Ticket</div>
            </a-button>
            <br />
            <a-button
              v-if="record.folio"
              type="link"
              @click="
                openDocument(record.folio.bucketName, record.folio.fileName)
              "
              primary
            >
              Folio
            </a-button>
            <a-button v-else type="link">
              <div class="uploadImgLabel">Folio</div>
            </a-button>
          </template>

          <template #redeem="{ record }">
            <a-popconfirm
              v-if="record.status === 'AWAIT_APPROVE'"
              title="Are you sure approve this redeem?"
              ok-text="Approve"
              cancel-text="Reject"
              @confirm="confirmApprove(record.id)"
              @cancel="confirmReject(record.id)"
            >
              <a-button
                :class="{
                  waiting: record.status === 'AWAIT_APPROVE',
                  approved: record.status === 'APPROVE',
                  reject: record.status === 'REJECT',
                }"
              >
                <div v-if="record.status === 'AWAIT_APPROVE'">
                  AWAIT APPROVE
                </div>
                <div v-else>{{ record.status }}</div>
              </a-button>
            </a-popconfirm>
            <p
              v-else
              :class="{
                waiting: record.status === 'AWAIT_APPROVE',
                approved: record.status === 'APPROVE',
                reject: record.status === 'REJECT',
              }"
            >
              {{ record.status }}
            </p>
          </template>
        </a-table>
      </a-col>
    </div>
  </office-layout>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import { usePagination } from "vue-request";
import {
  HomeOutlined,
  SearchOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons-vue";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  reactive,
  toRefs,
} from "vue";
import { message } from "ant-design-vue";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    HomeOutlined,
    SearchOutlined,
    PlusCircleOutlined,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      loading: false,
      current: 1,
      total: 0,
      pageSize: 10,
      searchText: "",
      searchedColumn: "",
    });
    const searchInput = ref();

    const fetchRedeemList = async (page = 1, filters = {}, sorter = {}) => {
      try {
        state.loading = true;
        let payload = {
          page,
          pageSize: state.pageSize,
          ...filters,
        };

        if (sorter.field) {
          payload = {
            ...payload,
            sortField: sorter.field,
            sortOrder: sorter.order == "ascend" ? 1 : -1,
          };
        } else {
          payload = {
            ...payload,
            sortField: "createdAt",
            sortOrder: -1, // desc
          };
        }

        const result = await store.dispatch(
          `redeem/fetchRedeemListAdmin`,
          payload
        );
        state.current = page;
        state.total = result.total;
        state.loading = false;
      } catch (error) {
        state.loading = false;
      }
    };

    const confirmApprove = async (id) => {
      try {
        await store.dispatch("redeem/approve", id);
        message.success("Approved!!");
        await fetchRedeemList();
      } catch (error) {
        message.error("Approve Failed!!");
      }
    };
    const confirmReject = async (id) => {
      try {
        await store.dispatch("redeem/reject", id);
        message.success("Rejected!!");
        await fetchRedeemList();
      } catch (error) {
        message.error("Reject Failed!!");
      }
    };

    onMounted(async () => {
      await fetchRedeemList();
    });

    const handleTableChange = async (pag = {}, filters, sorter) => {
      await fetchRedeemList(pag.current, filters, sorter);
    };

    const handleSearch = async (selectedKeys, confirm, dataIndex) => {
      state.searchText = selectedKeys[0];
      state.searchedColumn = dataIndex;
      await fetchRedeemList(1, { [`${dataIndex}`]: selectedKeys });
      confirm();
    };

    const handleReset = async (clearFilters) => {
      state.searchText = "";
      await fetchRedeemList();
      clearFilters();
    };

    const columns = [
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Hotel Name",
        dataIndex: "hotelName",
      },
      {
        title: "Sha Code",
        dataIndex: "shaCode",
      },
      {
        title: "Phone Number",
        dataIndex: "phoneNumber",
      },
      {
        title: "Date",
        slots: {
          customRender: "checkdate",
        },
      },
      {
        title: "Total(฿)",
        dataIndex: "total",
        sorter: true,
        slots: {
          filterDropdown: "filterDropdown",
          filterIcon: "filterIcon",
          customRender: "total",
        },
      },
      {
        title: "Document",
        slots: {
          customRender: "documents",
        },
      },

      {
        title: "Status",
        dataIndex: "status",
        sorter: true,
        slots: {
          filterDropdown: "filterDropdown",
          filterIcon: "filterIcon",
          customRender: "redeem",
        },
      },
    ];

    const openDocument = async (bucketName, fileName) => {
      try {
        const { data } = await store.dispatch(`bucket/getPrivateUrl`, {
          bucketName,
          fileName,
        });
        window.open(data.url, "_blank");
      } catch (error) {
        message.error("Cannot get link");
      }
    };

    return {
      moment,
      ...toRefs(state),
      redeems: computed(() => store.getters["redeem/getDataList"]),
      pagination: computed(() => ({
        total: state.total,
        current: state.current,
        pageSize: state.pageSize,
      })),
      handleTableChange,
      handleSearch,
      handleReset,
      searchInput,
      columns,
      confirmApprove,
      confirmReject,
      openDocument,
    };
  },
});
</script>

<style scoped>
.waiting {
  color: rgb(158, 146, 14);
}
.approved {
  color: green;
}
.reject {
  color: red;
}
.uploadImgLabel {
  color: rgb(189, 184, 184);
}
</style>
